<template lang="pug">
    .celebrity-program
        ._wrap
            ._about
                ._about-title {{ locale("compare") }}
                ._about-list-title {{ locale("desc") }}
                ul._about-list
                    li._about-list-item {{ locale("get-know") }}
                    li._about-list-item {{ locale("collaborate") }}
                    li._about-list-item {{ locale("talk") }}
                    li._about-list-item {{ locale("date") }}
            ._persons
                ._top-left-decor
                ._top-right-decor
                ._bottom-left-decor
                ._bottom-right-decor
                router-link._person(v-for="person in persons" :to="{name: 'person', params: {uuid: person.personId}}" :key="person.personId")
                    img(:src="person.personAvatar" v-if="person.personAvatar")._person-avatar
                router-link(to="/persons/celebrity" tag="a")._more-persons {{ locale("all") }}
</template>

<script>
import { friendMixins } from "../../mixins/friendMixins";

export default {
    name: "CelebrityProgram",
    mixins: [friendMixins],
    data: () => ({
        persons: [],
        limit: 6,
        filter: {
            gender: "ALL",
            age: "ALL",
            sort: "DATE",
            compare: "ALL",
            term: "",
        },
    }),
    mounted() {
        this.$store.dispatch("setPage", "celebrity-program");
    },
    async created() {
        await this.getPersons("celebrity", 1, this.limit, this.filter)
            .then((r) => {
                console.log(r);
                let response = r.data.celebritiesList.list;
                if (response != null && response.length) {
                    response.forEach((person) => {
                        this.persons.push(person);
                    });
                }
            })
            .catch(() => {
                this.$msgBox(
                    this.localeError("error"),
                    this.localeError(
                        "personListNotReceived",
                    ),
                );
            });
    },
};
</script>

<style lang="scss">
@import "celebrity-program";
</style>
