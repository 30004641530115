<template lang="pug">
    .content__main
        celebrity-program
</template>

<script>
import celebrityProgram from "../components/celebrity-program/celebrity-program";

export default {
    name: "CelebrityProgram",
    components: { celebrityProgram },
};
</script>
